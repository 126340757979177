import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as dayjs from 'dayjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { AlarmsAddressComponent } from './alarms-address/alarms-address.component';
import { AlarmsGroupsComponent } from './alarms-groups/alarms-groups.component';
import { AlarmsKeywordComponent } from './alarms-keyword/alarms-keyword.component';
import Swal from 'sweetalert2'

import * as $ from 'jquery';

@Component({
  selector: 'app-organization-alarms',
  templateUrl: './organization-alarms.component.html',
  styleUrls: ['./organization-alarms.component.scss']
})
export class OrganizationAlarmsComponent implements OnInit {

  dayjs = dayjs;
  displayedColumns = ['date', 'keyword', 'actions'];
  dataSource = new MatTableDataSource<any[]>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _dialog: MatDialog,
    private _http: HttpClient,
    private _snackBar: MatSnackBar,
    private _loader: NgxSpinnerService) { }

  ngOnInit(): void { 
    this.load();
  }

  createAlarm() {
    this._dialog.open(OrganizationAlarmsDialogComponent, {
      width: '800px'
    }).afterClosed().subscribe(data => {
      if (data) {
        this.load();
      }
    })
  }

  load() {
    this._loader.show();
    this._http.get<any[]>(`${environment.api_url}/cloud/alarms`).subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this._loader.hide();
    }, error => {
      this._snackBar.open('Konnte Alarmierungen nicht abrufen!', 'ok', {
        duration: 3000,
        horizontalPosition: 'end'
      });
      this._loader.hide();
    });
  }

  sendAgain(id) {
    this._loader.show();
    this._http.get(`${environment.api_url}/cloud/alarms/${id}`).subscribe(data => {
      this._dialog.open(OrganizationAlarmsDialogComponent, {
        width: '800px',
        data: data
      }).afterClosed().subscribe(data => {
        if (data) {
          this.load();
        }
      });
      this._loader.hide();
    }, error => {
      this._loader.hide();
      this._snackBar.open('Konnte Alarmierungen nicht abrufen!', 'ok', {
        duration: 3000,
        horizontalPosition: 'end'
      });
    });
  }
}

@Component({
  selector: 'app-organization-alarms-dialog',
  templateUrl: './create-alarm.html'
})
export class OrganizationAlarmsDialogComponent implements OnInit {
  @ViewChild(AlarmsKeywordComponent) public keyword_comp: AlarmsKeywordComponent;
  @ViewChild(AlarmsGroupsComponent) public groups_comp: AlarmsGroupsComponent;
  @ViewChild(AlarmsAddressComponent) public address_comp: AlarmsAddressComponent;

  constructor(private _http: HttpClient,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<OrganizationAlarmsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data) {}

  ngOnInit(): void {
    this._dialogRef.disableClose = true;
    this._dialogRef.backdropClick().subscribe(() => {
      this.onClose();
    });
    this._dialogRef.keydownEvents().subscribe(key => {
      if (key.code === 'Escape') this.onClose();
    });
  }

  onClose() {
    const swalResult = Swal.fire({
      title: 'Wirklich abbrechen?',
      text: 'Möchten Sie wirklich diese Alarmierung abbrechen?',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    });
    swalResult.then(result => {
      if (result.isConfirmed) {
        this._dialogRef.close();
      }
    });
  }

  ngAfterViewInit() {
    this.address_comp.form.valueChanges.subscribe(data => {
      this.safeAlarmed = false;
    });
    this.keyword_comp.form.valueChanges.subscribe(data => {
      this.safeAlarmed = false;
    });

    if (this._data) {
      this.address_comp.model = {
        street: this._data.street,
        city: this._data.city,
        zipcode: this._data.zipcode,
        appartment: this._data.appartment
      };
      this.keyword_comp.model = {
        keyword: this._data.keyword,
        message: this._data.alarmText
      }
    }
  }

  safeAlarmed = false;

  safeAlarm() {
    this.safeAlarmed = true;
  }

  sendAlarm() {
    const data = {
      channel: this.groups_comp.groupList.filter(i => i.checked == true).map(i => i.id),
      message: this.keyword_comp.model.message,
      keyword: this.keyword_comp.model.keyword,
      street: this.address_comp.model.street,
      city: this.address_comp.model.city,
      appartment: this.address_comp.model.appartment,
      zipcode: this.address_comp.model.zipcode
    }

    this._http.post(`${environment.api_url}/alarm`, data).subscribe(result => {
      this._snackBar.open('Alarmierung wurde erfolgreich gesendet!', 'ok', {
        duration: 3000,
        horizontalPosition: 'end'
      });
      this._dialogRef.close(true);
    }, error => {
      this._snackBar.open('Alarmierung konnte aufgrund eines internen Serverfehlers nicht gesendet werden!', 'ok', {
        duration: 3000,
        horizontalPosition: 'end'
      });
    });
  }
}
